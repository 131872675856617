@import 'bootstrap/dist/css/bootstrap.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FFFFFF;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbar {
  background-color: #288b9b !important;
  color: #FFFFFF;
}

.home-page-title,
.input-area,
.result-area,
.submit-area {
  align-content: center !important;
  align-items: center !important;
  margin-top: 20px;
}

.multiline {
  white-space: pre-line;
}

.submit-button {
  background-color: #22B5CC !important;
  border-color: #288b9b !important;
}
